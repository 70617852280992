import { Group, Text } from "@mantine/core";
import Image from "next/image";
import { FC } from "react";

export const LogoType: FC = () => (
  <Group wrap="nowrap">
    <Image src="/neoflex_logo.png" alt="NEOTRACK" width={40} height={40} />
    <Text span fw="bold" c="var(--mantine-color-text)" size="xl">
      <Text span c="#a1002f" size="xl">
        NEO
      </Text>
      TRACK
    </Text>
  </Group>
);
