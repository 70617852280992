import { SignInResponse, signIn } from "next-auth/react";
import { useSWRConfig } from "swr";
import { z } from "zod";
import { useSWRMutationWithError } from "~/lib/hooks/useSWRMutationWithError";

export const LoginArgs = z.object({
  email: z.string().email("Geçersiz e-posta adresi"),
  password: z.string().min(1, "Şifre boş bırakılamaz"),
});

export type LoginArgs = z.infer<typeof LoginArgs>;

export const useLogin = () => {
  const { onError } = useSWRConfig();
  return useSWRMutationWithError<SignInResponse | undefined, SignInResponse | undefined, string, LoginArgs>(
    "login",
    async (_key, { arg }) => {
      const response = await signIn("credentials", { ...arg, redirect: false });

      if (!response?.ok) {
        throw response;
      }

      return response;
    },
    {
      // @ts-ignore
      onError,
    }
  );
};
